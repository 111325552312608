import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import './../styles/tailwind.css';
import ThemeToggler from './themeToggler';
import { ThemeContext, themes } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Layout = ({ location, title, children }) => {
  const windowGlobal = typeof window !== 'undefined' && window;

  const themeLocalStorage = windowGlobal && windowGlobal.localStorage.getItem('theme');
  const [currentTheme, setCurrentTheme] = useState(themeLocalStorage ? themeLocalStorage : 'light');

  useEffect(() => {
    windowGlobal.localStorage.setItem('theme', currentTheme);
  }, [windowGlobal, currentTheme]);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          social {
            twitter
          }
        }
      }
    }
  `);

  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname !== rootPath) {
    header = (
      <h3 className="mt-0 pt-3 text-center text-lg text-teal-500 font-bold tracking-widest">
        <Link className="no-underline" to={`/`}>
          {' '}
          {title}
        </Link>
      </h3>
    );
  }

  const theme = themes[currentTheme];
  const locations = location.pathname.split('/').filter(l => l);
  const divider = (
    <li className="flex">
      <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
    </li>
  );

  return (
    <ThemeContext.Provider value={[theme, newTheme => themes[newTheme]]}>
      <div className="min-h-screen flex flex-col">
        <ThemeToggler onThemeChange={userTheme => setCurrentTheme(userTheme)} currentTheme={currentTheme} />
        {header && (
          <header className={`duration-300 transition-colors w-full ${theme.background} pb-3`}>{header}</header>
        )}
        {header && (
          <div className={`${theme.backgroundExtraLight} ${theme.textLight}`}>
            <div className="mx-auto max-w-6xl p-2 whitespace-no-wrap overflow-y-auto">
              <ul className="flex items-center space-x-2 uppercase text-xs list-outside list-none ml-0">
                <li>
                  <Link className="no-underline" to="/">
                    Inicio
                  </Link>
                </li>
                {locations.length > 0 && divider}
                {locations.map((breadcrumb, index) => {
                  const url = `/${locations.slice(0, index + 1).join('/')}`;
                  return (
                    <React.Fragment key={breadcrumb}>
                      <li>
                        {index === locations.length - 1 && <span className="">{breadcrumb.replace(/-/g, ' ')}</span>}
                        {index < locations.length - 1 && (
                          <Link to={url} className="no-underline ">
                            {breadcrumb.replace(/-/g, ' ')}
                          </Link>
                        )}
                      </li>
                      {index < locations.length - 1 && divider}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <main className={`duration-300 transition-colors flex flex-col flex-1 ${theme.backgroundLight}`}>
          {children}
        </main>
        <footer
          className={`duration-300 transition-colors flex-shrink-0 p-3 text-center ${theme.text} ${theme.background}`}
        >
          © {new Date().getFullYear()} | Powered by{' '}
          <a
            href={`https://www.twitter.com/${data.site.siteMetadata.social.twitter}`}
            target="_blank"
            rel="noreferrer noopener"
            className="font-bold text-teal-500 no-underline"
          >
            {data.site.siteMetadata.social.twitter}
          </a>
        </footer>
      </div>
    </ThemeContext.Provider>
  );
};

export default Layout;
